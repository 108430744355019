import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Page from "../components/page"
import { page } from "../content/terms"

const IndexPage = () => (
  <Layout>
    <SEO title={page.title} />
    <Page title={page.title} content={page.content} />
  </Layout>
)

export default IndexPage
